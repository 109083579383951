import React from 'react'
import Navbar from '../../components/Navabar'
import Centralize from '../../components/Centralize'
import Derms from '../../components/Derms'
import Approach from '../../components/Approach'
import Fourthsection from '../../components/Fourthsection'
import Creativity from '../../components/Creativity'
import Tricomp from '../../components/Tricomp'
import OurBlogs from '../../components/OurBlogs'
import ContactUs from '../../components/ContactUs'
import Footer from '../../components/Footer'
import { useEffect } from 'react'
import { useState } from 'react'

const Landing = () => {
  const [goToContactUs, setGoToContactUs] = useState(JSON.parse(localStorage.getItem("isScroll") || "false"));

  useEffect(() => {
    if (goToContactUs) {
      scrollToContactUs();
    }
  }, [goToContactUs])

  const scrollToContactUs = () => {
    const contactUsSection = document.getElementById('contact-us');
    if (contactUsSection) {
      contactUsSection.scrollIntoView({ behavior: 'smooth' });
      setGoToContactUs(false);
      localStorage.setItem("isScroll", "false");
    }
  };

  return (
    <>
      <Navbar />
      <Centralize />
      <Derms />
      <Approach />
      <Fourthsection />
      <Creativity />
      <Tricomp />
      <OurBlogs />
      <ContactUs />
      <Footer />
    </>
  )
}

export default Landing