import React from "react";
import WindMillMainImg from "../../assets/images/WindMillMainImg.png";
import Vector from "../../assets/images/Vector.png"
import Navbar from "../Navabar";
import Footer from "../Footer";
import "./BlogDetails.scss";
import { useNavigate } from "react-router-dom";
import { Link} from 'react-router-dom';



const BlogDetails = () => {
  const navigate = useNavigate();

  const scrollToContactUs = () => {
    console.log("pathname", window.location.href)
    if (window.location.href.endsWith("/blog-details")) {
      // window.location.href = "/";
      localStorage.setItem("isScroll", "true");
      navigate('/');
    }
    const contactUsSection = document.getElementById('contact-us');
    if (contactUsSection) {
      contactUsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Navbar/>
    <div className="blogdetail-container p-lg-0 p-md-0 p-sm-0">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-7 col-md-6 col-sm-10">
          <h2 className="blogtitle">Blogs</h2>
          <h3 className="blogdetail">How DERMS is Revolutionizing India's Energy Landscape and Transforming The Future Of Energy?</h3>
          <h5 className="dateandtime">6 December, 2023</h5>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-lg-7 col-md-6 col-sm-10">
          <div className="card border-0">
            <img src={WindMillMainImg} className="card-img-top" alt="Card" />
            <div className="card-body mt-4">
              <h2 className="blogheading">Introduction</h2>
              <p className="card-text">In 2021, the Government of India introduced the Panchamrit Plan at COP26 where they set an ambitious goal to elevate India's energy game by boosting non-fossil energy capacity to 500 GW by 2030 and aiming for 50% of energy from renewables. With an eye on achieving Net Zero emissions by 2070, the Panchamrit plan is poised to revolutionize India's power sector, driving the vast adoption of innovative technologies including DERMS. </p><br></br>
              <p className="card-text">So, In this blog, we will help you to understand What DERMS is and how DERMS fits into the vision of the Government Of India’s combat plan against climate change, driving positive change across the nation. </p>
              <h3 className="blogheading">What is DERMS and Why Does it Matter?</h3>
              <p className="card-text">DERMS is like the maestro of an orchestra, ensuring that all the instruments (or in this case, energy sources like solar panels and wind turbines) play in harmony. </p>
              <p className="card-text">Let’s be specific-  DERMS is a combination of hardware and software systems used in the field of energy management, particularly in the context of smart grids and renewable energy integration. DERMS enables the efficient management and optimization of various distributed energy resources, such as solar panels, wind turbines, energy storage systems, electric vehicles, and demand response programs. The system helps utilities and grid operators to monitor, control, and coordinate these distributed energy resources to ensure grid stability, reliability, and optimal energy use.</p>
                <h3 className="blogheading">What Are The Benefits of DERMS?</h3>
                <p className="card-text">As we shift towards using more renewable energy, DERMS helps keep our electricity grid stable and reliable, even when the sun isn't shining or the wind isn't blowing. Here are some benefits of DERMS:</p>
                <ul className="card-text">
                  <li className="dermslist"><strong>Integration of Renewable Energy:</strong> With the increasing adoption of renewable energy sources like solar and wind, DERMS helps utilities manage the variability and intermittency of these resources. It allows for the effective integration of renewable energy into the grid while maintaining stability and reliability.</li>
                  <li className="dermslist"><strong>Grid Stability and Resilience:</strong> DERMS enables utilities to balance supply and demand in real time, mitigating grid imbalances and fluctuations caused by distributed energy resources. This helps to ensure grid stability and resilience, reducing the risk of blackouts or brownouts.</li>
                  <li className="dermslist"><strong>Optimization of Energy Resources:</strong> By coordinating and optimizing distributed energy resources, DERMS helps utilities maximize the use of clean energy sources, minimize energy waste, and reduce overall operational costs.</li>
                  <li className="dermslist"><strong>Demand Response Management:</strong> DERMS facilitates demand response programs by enabling utilities to remotely control and manage energy consumption from various sources, such as electric vehicles and smart appliances. This helps to alleviate peak demand periods, reduce stress on the grid, and avoid the need for expensive infrastructure upgrades.</li>
                  <li className="dermslist"><strong>Enhanced Grid Flexibility:</strong> DERMS provides utilities with greater flexibility and agility in managing the grid, allowing for faster response times to changes in energy supply and demand. This flexibility is particularly important in the context of the evolving energy landscape and the increasing adoption of distributed energy resources.</li>
                </ul>
                <h3 className="blogheading">The Rise of Renewables and the Role of DERs</h3>
                <p className="card-text">India's energy mix is undergoing a profound shift, with renewables playing an increasingly significant role. Distributed Energy Resources (DERs) are emerging as key players in this transition, offering local sustainability solutions and contributing to India's Net Zero targets. DERs, which include rooftop solar PV units, wind farms, battery energy storage systems (BESS), and electric vehicles (EVs), are revolutionizing the way we generate and consume energy.</p>
                <h3 className="blogheading">DERMS Tackling Energy Challenges Head-On</h3>
                <p className="card-text">Ever wondered what happens when there's a cloudy day or a lull in the wind, but we still need electricity? That's where DERMS swoops in to save the day! By cleverly managing these ups and downs in renewable energy, DERMS ensures that we always have enough power to go around, no matter what.</p>
                <h3 className="blogheading">Stories of Success With DERMS</h3>
                <p className="card-text">Let's take a closer look at how DERMS is already making a difference. In states like Tamil Nadu, Karnataka, and Gujarat, DERMS is helping integrate more solar and wind power into the grid smoothly. Plus, it's introducing cool new ideas like time-of-use tariffs, which can help you save money on your electricity bill!</p>
                <h3 className="blogheading">Unlocking DERMS' Potential For Consumers</h3>
                <p className="card-text">Picture this: a world where your home knows exactly when to turn on the dishwasher or charge your electric car to save you money on your electricity bill. That's the kind of magic DERMS can make happen by using smart technology to manage energy more efficiently.</p>
                <h3 className="blogheading">Looking to the Future Of DERMS </h3>
                <p className="card-text">As India sets its sights on a future powered by renewable energy, DERMS will play a crucial role in making it happen. With DERMS on our side, we're moving towards a world where clean, green energy is the norm, not the exception.</p>
                <h3 className="blogheading">Conclusion</h3>
                <p className="card-text">In the quest for a cleaner, greener tomorrow, DERMS is our guiding light. With its ability to optimize resources, enhance grid stability, and empower stakeholders, DERMS is not just reshaping India's energy landscape – it's lighting the way towards a brighter, more sustainable future for all of us. We <strong>“Trillectric”</strong> are proud to be at the forefront of this energy revolution, partnering with stakeholders across India to drive positive change and build a greener, more resilient energy infrastructure for generations to come.</p>
        <p> Get in Touch  -  <span> <a href="https://calendly.com/trillectric/30min" target="_blank" rel="noopener noreferrer" className="meeting"> Schedule Meeting
  </a></span>  </p> 
              </div>
              <div className="card-footer box">
                <div className="card-footer-content">
                  <p className="card-text1">Unlock new corporate sustainability solutions for your business</p>
                  <button className="btn btn-primary">
    <a href="https://calendly.com/trillectric/30min" target="_blank" rel="noopener noreferrer">
        Get in touch <img className="arrowimg" src={Vector} />
    </a>
</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetails;
