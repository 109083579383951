import React from 'react'
import './Fourthsection.scss'
import fourthbg from "../assets/images/hardware_suite.png"



const Fourthsection = () => {
  return (
  
    <div className="image-with-content ps-0">
  {/* <div className='container'> */}
       <div className='row align-items-center justify-content-between'> 
    <div className="col-lg-6 col-md-6 col-sm-12 col-12 mb-lg-0 mb-md-0 mb-sm-0 mb-4">
      <img src={fourthbg} alt="background" className="fourth-image" />
    </div>
        <div className="col-lg-5 col-md-6 col-sm-12 col-12 ">
          <div className='content_advanced_section ps-lg-0 ps-md-0 ps-sm-4 ps-4'>
          <h2 className="fourth-heading">Our Advanced <br /> <span>Hardware</span> Suite </h2>
      <p className="fourth-paragraph mb-4">Experience enhanced efficiency with the <span className='solar_data'>Solar Data Logger</span> and B2G Controller, optimizing energy use. </p>
      <p className="fourth-paragraph">Seamlessly integrate the Energy Monitor and 2-way IR Blaster for complete demand management, empowering aggregators with control and insights. </p>      
          </div>
    </div>
   </div>
   {/* </div> */}
  </div>

  )
}

export default Fourthsection