import React, { useContext } from 'react';
import './ContactUs.scss'

import { useRef, useState } from 'react';
import submit from "../assets/images/submit.svg"
import { useNavigate } from 'react-router-dom';
import { UIContext } from '../contexts/UIContext';

const ContactUs = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    project: ''
  });
  // const fileInputRef = useRef(null);

  // const handleFileInputClick = () => {
  //   fileInputRef.current.click();
  // }

  const { addHighlightClass, removeHighlightClassHandler } = useContext(UIContext)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const uniqueID = "TRI-" + Math.floor(1000 + Math.random() * 9000);
    // console.log("files", fileInputRef.current.files[0]);

    const data = new FormData();
    data.append('id', uniqueID);
    data.append('name', formData.name);
    data.append('email', formData.email);
    data.append('project', formData.project);
    // data.append('attachment', fileInputRef.current.files[0]);

    const Sheet_Url = "https://script.google.com/macros/s/AKfycbwiUKDRVNYwlNXhrvA632zo_sdEfQVb_RTtB5q2dIMtzDl6cs9iO5NPY0EyvRxTfw1E/exec "

    try {
     const response= await fetch(Sheet_Url, {
        method: 'POST',
        body: data,
        muteHttpExceptions: true,
      });
      console.log( response ,'response')
      setFormData({
        name: '',
        email: '',
        project: '',
      });
      alert("Form submitted successfully!");

      // navigate('/');
      window.location.href = "/"
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert("something went wrong")
      console.log(error);
    }
  };

  return (
    <div className={['contactus', addHighlightClass ? "highlight" : ""].join(" ")} id='contact-us'>
      <div className='container'>
        <div className="contact-form">
          <div className='row justify-content-between'>
            <div className="col-lg-5 col-12">
              <div className="contact-details">
                <h2>Drop Us A <br className='d-lg-block d-md-block d-none d-none' /><span>Line!</span> </h2>
                <p>Don’t hesitate to contact us, we’d love to hear your story.</p>
              </div>
            </div>
            <div className='col-lg-6 col-12'>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <div className="flex-container">
                    <div className="form-field">
                      <label htmlFor="name">Your Name <sup>*</sup></label>
                      <input className='first_name' type="text" id="name" name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter your name"
                        required />
                    </div>
                    <div className="form-field">
                      <label htmlFor="email">Your Email <sup>*</sup></label>
                      <input type="email" id="email" name="email"
                        value={formData.email}
                        onChange={handleChange}
                        onFocus={removeHighlightClassHandler}
                        placeholder="Enter your email"
                        required />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="project">Tell us about your project</label>
                  <textarea id="project" name="project"
                    value={formData.project}
                    onChange={handleChange}
                    onFocus={removeHighlightClassHandler}
                    placeholder="Enter your project details"></textarea>
                </div>
                {/* <div className="form-group">
                  <label htmlFor="attachments">Attachments</label>
                  <div className="custom-file-input" onClick={handleFileInputClick}>
                    <span>Add Files</span>  or drop files here
                  </div>
                  <input
                    type="file"
                    id="attachments"
                    name="attachment"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                  />
                </div> */}
                <button type="submit" class="submit-button" disabled={loading}>
                  {loading ? (
                    <>
                      <div className="loader"></div>
                      <span className="button-text">Submitting</span>
                    </>
                  ) : (
                    <>
                      <img src={submit} alt="Submit" className="button-icon" />
                      <span className="button-text">Submit</span>
                    </>
                  )}
                  {/* <img src={submit} alt="Submit" className="button-icon"></img>
                  {loading ? <span className="button-text">Submitting</span> : <span className="button-text">Submit</span>} */}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
