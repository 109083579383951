import React from 'react';
import './Creativity.scss';
import trilogo from '../assets/images/creativity1.svg';

const Creativity = () => {
  return (
    <div className="component-with-cards">
      <div className="container">
        <div className="row d-flex justify-content-between position-relative">
          <div className="header mt-lg-5 mt-md-5 mt-sm-4 mt-4 ">
            <h2 className="Creativity-heading">
            The Trillectric Edge
              {/* Our approach <span>Creativity.</span>{' '} */}
            </h2>
          </div>
          <div className="col-lg-5 col-12 mb-5">
            <div className="Creativity-card">
              <h3 className="Creativity-card-heading">System Agnostic Integration</h3>
              <p className="Creativity-card-paragraph">
              Connects with all energy systems <br className="d-lg-block d-md-block d-sm-block d-block"/> Centralizes control for diverse setups <br className="d-lg-block d-md-block d-sm-block d-block"/> Harnesses data for actionable intelligence
              </p>
              <h3 className="Creativity-card-subheading">Unified Data Highway</h3>
              <p className="Creativity-card-subtext">
                Unites data nodes seamlessly <br className="d-lg-block d-md-block d-sm-block d-block"/> Streamlines real-time data flow <br className="d-lg-block d-md-block d-sm-block d-block"/>Predictive modeling for optimal energy flow
              </p>
            </div>
          </div>
          <div className="col-lg-2 d-flex align-items-center justify-content-center">
            <img
              src={trilogo}
              alt="logo"
              className="Creativity-center-image"
            />
          </div>
          <div className="col-lg-5 col-12">
            <div className="Creativity-card right">
              <h3 className="Creativity-card-heading">AI Energy Control Bot </h3>
              <p className="Creativity-card-paragraph">
                Automates energy system analytics <br className="d-lg-block d-md-block d-sm-block d-block"/> Generates proactive management alerts <br className="d-lg-block d-md-block d-sm-block d-block"/> Visualizes and controls DER assets
              </p>
              <h3 className="Creativity-card-subheading">Reliable Infrastructure </h3>
              <p className="Creativity-card-subtext">
              Cost-effective, scalable hardware <br className="d-lg-block d-md-block d-sm-block d-block"/> Advanced security protocols<br className="d-lg-block d-md-block d-sm-block d-block"/> In-House AI for efficient data handling
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Creativity;
