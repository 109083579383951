import React from "react";
import daygraph from "../../assets/images/daygraph.svg";
import Vector from "../../assets/images/Vector.png"
import Navbar from "../Navabar";
import Footer from "../Footer";
import "./BlogDetails.scss";
import { useNavigate } from "react-router-dom";


const SecondBlog = () => {
  const navigate = useNavigate();

  const scrollToContactUs = () => {
    console.log("pathname", window.location.href)
    if (window.location.href.endsWith("/blog-details-two")) {
      // window.location.href = "/";
      localStorage.setItem("isScroll", "true");
      navigate('/');
    }
    const contactUsSection = document.getElementById('contact-us');
    if (contactUsSection) {
      contactUsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Navbar/>
    <div className="blogdetail-container p-lg-0 p-md-0 p-sm-0">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-7 col-md-6 col-sm-10">
          <h2 className="blogtitle">Blogs</h2>
          <h3 className="blogdetail">Navigating the Duck Curve: Trillectric's Smart Solutions for Grid Stability</h3>
          <h5 className="dateandtime">18 April, 2024</h5>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-lg-7 col-md-6 col-sm-10">
          <div className="card border-0">
            <img src={daygraph} className="card-img-top" alt="Card" />
            <div className="card-body mt-4">
              <h2 className="blogheading">Introduction</h2>
              <p className="card-text">As the sun climbs and sets, so does the production of solar energy, leading to a unique challenge known as the "Duck Curve." This phenomenon represents the mismatch between peak solar energy production and peak energy demand. Trillectric stands at the forefront of addressing this issue with innovative solutions that promote grid stability and energy efficiency. In this blog, we delve into the nature of the Duck Curve and how Trillectric's advanced Distributed Energy Resource Management System (DERMS) provides the necessary tools to tackle this challenge head-on. </p><br></br>
              {/* <p className="card-text">So, In this blog, we will help you to understand What DERMS is and how DERMS fits into the vision of the Government Of India’s combat plan against climate change, driving positive change across the nation. </p> */}
              <h3 className="blogheading">Understanding the Duck Curve:
</h3>
              <p className="card-text">The Duck Curve is a graph that shows the difference between electricity demand and the amount of solar energy supplied throughout the day. In the middle of the day, there's an overproduction of solar energy leading to a significant dip in the curve, resembling the belly of a duck. As the sun sets, solar production drops, but the demand increases, leading to a steep ramp-up in needed energy production, resembling the duck's neck.
 </p>
              {/* <p className="card-text">Let’s be specific-  DERMS is a combination of hardware and software systems used in the field of energy management, particularly in the context of smart grids and renewable energy integration. DERMS enables the efficient management and optimization of various distributed energy resources, such as solar panels, wind turbines, energy storage systems, electric vehicles, and demand response programs. The system helps utilities and grid operators to monitor, control, and coordinate these distributed energy resources to ensure grid stability, reliability, and optimal energy use.</p> */}
              <h3 className="blogheading mt-lg-5 mt-md-2 mt-sm-2 mt-2">The Impact of the Duck Curve:
?</h3>
                <p className="card-text">As we shift towards using more renewable energy, DERMS helps keep our electricity grid stable and reliable, even when the sun isn't shining or the wind isn't blowing. Here are some benefits of DERMS:</p>
                <ul className="card-text mb-4">
                  <li className="dermslist"><strong>Grid Stress:</strong> Rapid ramp-up of non-renewable power plants to meet evening demands.
</li>
                  <li className="dermslist"><strong>Energy Waste:</strong> Midday solar energy surplus often leads to wastage or even negative pricing.
.</li>
                  <li className="dermslist"><strong>Inefficient Use:</strong> Current infrastructure isn't fully equipped to store excess solar energy.
</li>
                  {/* <li className="dermslist"><strong>Demand Response Management:</strong> DERMS facilitates demand response programs by enabling utilities to remotely control and manage energy consumption from various sources, such as electric vehicles and smart appliances. This helps to alleviate peak demand periods, reduce stress on the grid, and avoid the need for expensive infrastructure upgrades.</li>
                  <li className="dermslist"><strong>Enhanced Grid Flexibility:</strong> DERMS provides utilities with greater flexibility and agility in managing the grid, allowing for faster response times to changes in energy supply and demand. This flexibility is particularly important in the context of the evolving energy landscape and the increasing adoption of distributed energy resources.</li> */}
                </ul>
                <h3 className="blogheading mt-lg-5 mt-md-2 mt-sm-2 mt-2">Trillectric's Role in Smoothing the Curve:</h3>
                {/* <p className="card-text">As we shift towards using more renewable energy, DERMS helps keep our electricity grid stable and reliable, even when the sun isn't shining or the wind isn't blowing. Here are some benefits of DERMS:</p> */}
                <ul className="card-text">
                  <li className="dermslist"><strong>Advanced Scheduling and Storage Solutions:</strong> Trillectric’s DERMS optimizes energy storage, ensuring that excess energy produced during low-demand periods is stored efficiently and deployed when the demand surges.

</li>
                  <li className="dermslist"><strong>Real-Time Monitoring and Adjustment:</strong> With real-time grid monitoring, Trillectric’s systems can predict demand patterns and adjust the flow of energy, ensuring a more stable supply and preventing grid imbalances.

.</li>
                  <li className="dermslist"><strong>Seamless Integration with Existing Infrastructure::</strong> Our solutions integrate seamlessly with current grid infrastructures, allowing for smarter energy distribution without the need for costly overhauls.

</li>
                 <li className="dermslist"><strong>Consumer Engagement and Demand Response Programs:</strong> Trillectric empowers consumers to participate in demand response programs, turning energy consumers into active grid-balancing agents.
</li>
                  <li className="dermslist"><strong>Renewable Integration and Predictive Analytics: </strong> By harnessing the power of predictive analytics, Trillectric forecasts energy production and demand, ensuring that renewable resources are integrated smoothly into the grid.
</li> 
                </ul> 
                <p className="card-text">The Duck Curve presents a significant challenge, but with Trillectric’s DERMS, utilities, and energy providers can turn this challenge into an opportunity for a more sustainable future. By optimizing energy storage, enhancing grid monitoring, and involving consumers in energy management, Trillectric is not just flattening the curve; it's reshaping it into a symbol of efficiency and innovation. Join us as we lead the charge in revolutionizing energy management for a brighter, greener tomorrow.

</p>
<p className="card-text">Interested in learning more about Trillectric’s innovative solutions for your grid challenges? </p>

<p> Get in Touch  -  <span> <a href="https://calendly.com/trillectric/30min" target="_blank" rel="noopener noreferrer" className="meeting"> Schedule Meeting
  </a></span>  </p> 





              </div>
              <div className="card-footer box">
                <div className="card-footer-content">
                  <p className="card-text1">Unlock new corporate sustainability solutions for your business</p>
                  <button className="btn btn-primary">
    <a href="https://calendly.com/trillectric/30min" target="_blank" rel="noopener noreferrer">
        Get in touch <img className="arrowimg" src={Vector} />
    </a>
</button>                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SecondBlog;
