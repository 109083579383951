import React from "react";
import "./Derms.scss";
import graph from "../assets/images/graph2.png";
import { Link} from 'react-router-dom';

const Derms = () => {
  return (
    <>
    <div className="schedule-meeting">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8">
          <h1 className="my_title p-3 ps-0">Connect. Collaborate. <span className="d-block">Let's Schedule Your Meeting Today!</span></h1>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-8 col-10 d-flex justify-content-lg-start justify-content-md-start justify-content-sm-start justify-content-start">
            <Link className="d-flex  justify-content-lg-center justify-content-md-center justify-content-sm-center justify-content-center" to="https://calendly.com/trillectric/30min">
            <button className="schedule_btn">Schedule Meeting</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <div className="centered-content">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
          <h1 className="heading p-3 ps-0">Balancing Act: Navigating DER Challenges</h1>
          </div>
          {/* <div className="row"> */}
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="content_derms">
              <ul>
                <li className="derms_list">
                  <span>Mid-day Overload</span>
                  <p>Intense solar generation
contributes to surplus energy, risking reverse
power flow.</p>
                </li>
                <li className="derms_list">
                  <span>Evening Demand Surge</span>
                  <p>Peak EV charging
from 9 PM to 1 AM strains limited night time
capacity.</p>
                </li>
                <li className="derms_list">
                  <span>Visibility Gaps</span>
                  <p>Inadequate feeder-level
insights lead to poor capacity planning and grid
imbalances.</p>
                </li>
                <li className="derms_list">
                  <span>Voltage Instability</span>
                  <p>Inadequate feeder-level
insights lead to poor capacity planning and grid
imbalances.</p>
                </li>
                </ul>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="grap_image_container ps-lg-4 ps-md-4 ps-sm-0 ps-0">
              <img src={graph} alt="Image" className="derms-image" />
            </div>
          </div>
          </div>
        </div>
      </div>
      </>
  );
};

export default Derms;
