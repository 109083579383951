import React from 'react'
import './Approach.scss'
import technology from '../assets/images/Approach_bg.png'

const Approach = () => {
  return (
    <div className='approach-container mt-5'>
    <div className='container'>
        <div className='row'>
      <div className='col-lg-12'>
    <div className="left-column">
      <h2 className="appraoch-heading text-center">Our Approach </h2>
      <h3 className='appraoch-heading text-center mb-5'>Integrating Technology for Smarter Grids</h3>
      {/* <p className="appraoch-paragraph">With thousands of utility providers in over 50 <br></br> countries - and 95% coverage of US Utility <br></br> accounts - Arcadia provides the most <br></br> comprehensive access to the accurate</p> */}

    </div>
    </div>

      
    
      
     
    </div>
    <div className='col-lg-12 mt-3 technology-back d-flex justify-content-center'>
    <img src={technology}/>
{/* <div className='appraoch-card mt-lg-0 mt-md-0 mt-sm-0 mt-5'>
  
</div> */}
    </div>
  </div>
  </div>
  )
}

export default Approach