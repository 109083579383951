import React from "react"; 
import './Blogs.scss'
import Navbar from "../Navabar";

const Blogs = () => {
    return (
        <>
            <Navbar/>
        
        <div className='my-blog-component'>
    <div className="blog-component container container-heading">
        <div className='itscentered-div'>
      <h1 className="headers">our <span>blogs</span></h1>
      <p className="blogdescription pl-6">Explore our blog for expert insights, tips,<br className="d-lg-block d-md-block d-sm-block d-none"/>and inspiration to fuel your success journey.</p>
      
      </div>
    </div>
            </div>
            </>
    )
}
 
export default Blogs;