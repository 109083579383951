import React from 'react'
import './Talented.scss'
import job from "../assets/images/jobs.png"

const Talented = () => {
  return (
    <div className='job-component'>
    <div className="my-job-component container-fluid">
      <div className='centered-div'>
        <h1 className="heading text-center mt-lg-0 mt-md-0 mt-sm-2 mt-0">We are looking for <span> <br></br> Talented People</span> </h1>
      </div>
    </div>
    <div className='talented'>
      <img src={job} className='talented-back'></img>
      </div>

  </div>
  )
}

export default Talented