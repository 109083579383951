import React, {useEffect } from "react";
import Blogs from "./Blogs";
import BlogLists from "./BlogLists";
import Footer from "../Footer";
import "./RecentBlogs.scss";
import { Link , useLocation } from "react-router-dom"

const RecentBlogs = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
}, [pathname]);
  return (
    <div className="recent-blogs-container">
      <Blogs/>
    <div className="bloghistory">
      <div className="container">
      <h2 className="recentheading">Recent Blogs</h2>
      <div className="row card-container">
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 gx-3">
          <div className="card card-1 mb-0">
            <div className="card-body">
              <h5 className="card-title cards1">How DERMS is Revolutionizing India's Energy Landscape and Transforming The Future Of Energy?</h5>
              <div className="cardbtndiv">
              <Link to="/blog-details"><button className="card-button">read more
                  </button></Link>
              </div>
            </div>
          </div>
        </div>
     
        <div className="col-lg-6 col-md-6 col-sm-12 col-12 duck-curve mb-3 gx-3">
          <div className="card card-1 card_two mb-0">
            <div className="card-body">
              <h5 className="card-title cards1">Navigating the Duck Curve: Trillectric's Smart Solutions for Grid Stability</h5>
              <div className="cardbtndiv">
              <Link to="/blog-details-two"><button className="card-button">read more
                  </button></Link>
              </div>
            </div>
          </div>
        </div>  

         <div className="col-lg-6 col-md-6 col-sm-12 col-12 duck-curve gx-3">
        <div className="card card-1 card_three mb-0">
            <div className="card-body">
              <h5 className="card-title cards1">Islanding Prevention and Microgrid Management Strategies </h5>
              <div className="cardbtndiv">
              <Link to="/blog-details-three"><button className="card-button">read more
                  </button></Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-12 col-12 duck-curve mb-3 gx-3">
        <div className="card card-1 card_four mb-0">
            <div className="card-body">
              <h5 className="card-title cards1">Battery Storage Optimization: Strategies for Peak Shaving and Self-Consumption
</h5>
              <div className="cardbtndiv">
              <Link to="/blog-details-four"><button className="card-button">read more
                  </button></Link>
              </div>
            </div>
          </div>
        </div>


        <div className="col-lg-6 col-md-6 col-sm-12 col-12 duck-curve gx-3">
        <div className="card card-1 card_five mb-0">
            <div className="card-body">
              <h5 className="card-title cards1">Round-the-Clock EV Management: Powering Delhi's Electric Bus Revolution with Utility Expertise
</h5>
              <div className="cardbtndiv">
              <Link to="/blog-details-five"><button className="card-button">read more
                  </button></Link>
              </div>
            </div>
          </div>
        </div>
        
    </div>
    </div>
     </div>
      {/* <BlogLists/> */}
      <Footer/>
      </div>
  );
};

export default RecentBlogs;
