import { createContext, useEffect, useState } from "react";

const UIContext = createContext();

function UIContextProvider({ children }) {
  const [addHighlightClass, setAddHighlightClass] = useState(false);

  function addHighlightClassHandler() {
    console.log("function addHighlightClassHandler called")
    setAddHighlightClass(true);
  }

  function removeHighlightClassHandler() {
    setAddHighlightClass(false);
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
        setTimeout(() => setAddHighlightClass(false), 1000);
    })
  }, [])

  return (
    <UIContext.Provider
      value={{
        addHighlightClass,
        setAddHighlightClass,
        addHighlightClassHandler,
        removeHighlightClassHandler
      }}
    >
      {children}
    </UIContext.Provider>
  );
}

export default UIContextProvider;
export { UIContext };
