import React from 'react'
import './style.scss'
import logo from "../../assets/images/trillectric.svg"

const VCard = () => {
    const vCardDownload = () => {
      
        const a = document.createElement('a');
        a.href = "contact.vcf";
        a.download = 'trillectricContact.vcf';
        a.style.display = 'none';
        document.body.appendChild(a);
        // Trigger the click event on the anchor element to initiate the download
        console.log("HI");
        a.click();
    }

    return (
<div>
<div className="page">
<div className="upper">
<div className="upperCard">
<div className="image">
 <img className="profile" src={logo} alt="Avatar" />
</div>
<div className="name">
<h3 className='mt-3'>Trillectric</h3>
</div>
<div className="title">Empowering Discoms & DR Aggregators with Robust
Hardware <br/> & Data Infrastructure for DER Asset Management</div>
<div className="row__linked">
<a href="tel: +919971930808">
<div className="v-card-container">
<span className="fas fa-phone"></span>
<span className="text">Call</span>
</div>
</a>
<a href="mailto: arshi@trillectric.com ">
<div className="v-card-container border_lft-rtl">
<span className="fas fa-paper-plane"></span>
<span className="text">Email</span>
</div>
</a>
<a href="https://www.google.com/maps/place/Mohan+Cooperative+Industrial+Estate,+Badarpur,+New+Delhi"
target="_blank">

<div className="v-card-container">
<span className="fas fa-map-marker-alt"></span>
<span className="text">Location</span>
</div>
</a>
</div>


</div>
</div>
<div className="lower">
<div className="lowerCard">
<div className="vcard-details">

<div className="detail-container">
<div className="vcard-row ">
<span className="fas fa-phone" style={{color:'rgb(195, 192, 192)'}}></span>
<div className="inner-column">
<a href="tel: +919971930808">
<span className="detail-text">+91-9971930808</span>
</a>
<span className="detail-type">Mobile</span>
</div>
</div>
<hr/>
<div className="vcard-row ">
<span className="fa fa-envelope" style={{color:'rgb(195, 192, 192)'}}></span>
<div className="inner-column">
<a href="mailto: arshi@trillectric.com ">
<span className="detail-text">arshi@trillectric.com</span>
</a>
<span className="detail-type">Email</span>
</div>
</div>
<hr/>

<div className="vcard-row ">
<span className="fas fa-map-marker-alt" style={{color:'rgb(195, 192, 192)'}}></span>
<div className="inner-column">
<span className="address"> A-49,Block A
Mohan Cooperative Industrial Estate, Badarpur
New Delhi,110044,
India</span>
<a href="https://www.google.com/maps/place/Mohan+Cooperative+Industrial+Estate,+Badarpur,+New+Delhi"
target="_blank">
<div className="show-location">
<span className="location"> SHOW ON MAP</span>
</div>
</a>
</div>

</div>
<hr/>

<div className="vcard-row">
<span className="fas fa-globe" style={{color:'rgb(195, 192, 192)'}}></span>
<div className="inner-column">
<a href="https://trillectric.netlify.app/" target="_blank">
<span>Trillectric Website</span>
</a>
<span className="detail-type">Website</span>
</div>
</div>
<hr/>
{/* <div className="label">Social Media
</div>
</div>
<div className="media-icons">
<div className="social-media-container">
<a href="https://instagram.com/codalien?igshid=MzRlODBiNWFlZA==" target="_blank">

<span className="fab fa-instagram"
style=" font-size:28px; color:rgb(221, 67, 92);"></span>
</a>
</div>
<div className="social-media-container" onclick="socialMediaFunction()">
<a href="https://codalien.com/" target="_blank">
<span className="fas fa-globe"
style=" font-size:28px; color: rgb(145, 143, 143);"></span>
</a>
</div>
<div className="social-media-container" onclick="socialMediaFunction()">
<a href="https://www.youtube.com/@codalien" target="_blank">
<span className="fab fa-youtube" style=" font-size:28px; color: red;"></span>
</a>
</div>
<div className="social-media-container" onclick="socialMediaFunction()">
<a href="https://www.linkedin.com/company/codalien/" target="_blank">
<span className="fab fa-linkedin" style=" font-size:28px; color: #0072b1; "></span>
</a>
</div>
</div> 
 */}

</div>


</div>
<button className="vcard-file" onClick={vCardDownload}>
<span className="fas fa-user-plus" style={{color:'white' , fontSize:'12px'}}> Add to Contacts</span>
</button>
</div>
</div>
</div>
</div>

  )
}

export default VCard