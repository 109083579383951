import React, { useContext, useEffect, useState } from "react";
import './Footer.scss';
import footerlogo from "../assets/images/trillelectricimg.png"
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { setSelectionRange } from "@testing-library/user-event/dist/utils";
import { UIContext } from "../contexts/UIContext";

const Footer = () => {
  const navigate = useNavigate();

  const { addHighlightClassHandler } = useContext(UIContext);

  const scrollToContactUs = () => {

    addHighlightClassHandler();
    if (window.location.href.endsWith("/blogs") || window.location.href.endsWith("/blog-details")) {
      // window.location.href = "/";
      localStorage.setItem("isScroll", "true");
      navigate('/');
    }
    const contactUsSection = document.getElementById('contact-us');
    if (contactUsSection) {
      contactUsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [pathname]);
  return (
    <footer className="footer pb-0">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-lg-4">
            <div className="footer-section">
              <Link to="/"><img src={footerlogo} alt="Logo" className="footer-logo" /></Link>
              <p className="footer-text mt-4">Simplifying Distributed Energy Management with Advanced Solutions for a Sustainable Tomorrow</p>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="footer-section">
              <h3 className="footer-heading">Quick Links</h3>
              <ul className="footer-links mt-4">
                <li><Link to="/jobs">Jobs</Link></li>
                <li><Link to="/blogs">Blogs</Link></li>
                <li><Link to="https://www.termsfeed.com/live/eb40a193-e931-4537-b792-450855502799
">Privacy Policy</Link></li>


              </ul>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="footer-section">
              <h3 className="footer-heading">Drop Us a line</h3>
              <p className="footer-contact-text mt-4">Don’t hesitate to contact us, we’d <br className="d-lg-block d-md-block d-sm-block d-none" />love to hear your story.</p>
              <button className="custom_btn" onClick={scrollToContactUs}>Contact Us</button>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="footer-bottom">
        <span className="footer-company-name">© 2024 Codalien Technologies Pvt. Ltd.</span>
        <div className="footer-links-bottom">
          <Link to="#">Privacy Policy</Link>
          <Link to="#">Terms and Conditions</Link>
        </div>
      </div> */}
    </footer>
  );
};

export default Footer;