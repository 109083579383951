import React from 'react'
import './Tricomp.scss'
import background from '../assets/images/cards-bg.jpg'
import network from '../assets/images/tri-large.svg'
import insight from '../assets/images/img2.png'
import maintenance from '../assets/images/img3.png'

const Tricomp = () => {
  return (
    <div className='tricomp-container'>
    <div className="container mt-lg-5 mt-md-5 mt-sm-0 mt-0">
      <div className='row'>
      
        <div className='col-sm-10 col-12'>
        <h1 className='tricomp-title'>Unleash the full potential of data with <span className='dummy-text'>Trillectric’s insights.</span></h1>
        </div>
      </div>
      <div className='tricomp'>
      <div className="row d-flex align-items-start">

        <div className="col-lg-4 col-md-4 mb-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="card first-card">
                {/* <button className='card_btn'>BUTTON</button>
                <h2 className="title_text">Lorem Ipsum<span className='d-block number'>50</span></h2> */}
                    <img src={network} />
                </div>
            </div>
            <div className="col-lg-12">
              <div className="card text_card">Navigate grid intricacies with detailed network visualizations.</div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mb-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="card third_card mb-lg-4 mb-md-4 mb-sm-0 mb-0">
              <img src={insight} />
              </div>
            </div>
            <div className="col-lg-12 mt-lg-0 mt-md-0 mt-sm-3 mt-3">
              <div className="card fourth-card  mb-0">
              <img src={maintenance} />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 mt-lg-0 mt-md-0 mt-sm-3 mt-3">
          {/* <div className="row">
            <div className="col-lg-12 mt-lg-0 mt-md-0 mt-sm-3 mt-3"> */}
              <div className="card card_bg mb-0 mt-lg-0 mt-md-0 mt-sm-3">
              <img src={background} alt="Logo"  />
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    // </div>
    // </div>
  )
}

export default Tricomp