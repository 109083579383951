import React, {useEffect } from "react";
import './Navbar.scss';
import logo from "../assets/images/tri.png"
import { useState } from 'react';
 import Offcanvas from 'react-bootstrap/Offcanvas';
import { Link ,useLocation } from 'react-router-dom';


const Navbar = () => {
  const { pathname } = useLocation();
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
}, [pathname]);

const handleLinkClick = (path) => {
  if (path === pathname) {
    handleClose(); 
  }
};
  return (
    <nav className="navbar px-3 py-0">
      <div className="container d-block">
        <div className='row d-flex align-items-center'>
        <div className="col-lg-3 col-6">
          <Link to="/">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        </div>
        <div className="col-lg-9  col-6 d-flex justify-content-end align-items-center">
        <button onClick={handleShow} className="hamburger-button">
        ☰
      </button>
       
            <div className='content-wrapper'>
          <ul className="navbar-items">
            <li className="navbar-item">< Link to ="/jobs">Jobs</Link></li>
            <li className="navbar-item"><Link to ="/blogs">Blogs</Link></li>
          </ul>
          <div className="navbar-buttons ms-3 d-none">
            <button className="btn">Sign In</button>
            <button className="btn">Sign Up</button>
          </div>
          </div>
        </div>
      </div>
  
      </div>
      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
          <Link to="/">
          <img src={logo} alt="Logo" className="canva-logo" />
          </Link>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='content-wrapper'>
        <ul className="navbar-items mb-0">
        <li className="navbar-item pb-3">
                <Link
                  to="/jobs"
                  onClick={() => handleLinkClick("/jobs")}
                >
                  Jobs
                </Link>
              </li>
              <li className="navbar-item pb-3">
                <Link
                  to="/blogs"
                  onClick={() => handleLinkClick("/blogs")}
                >
                  Blogs
                </Link>
              </li>
            {/* <li className="navbar-item">< Link to ="/jobs">Jobs</Link></li> */}
            {/* <li className="navbar-item">< Link to ="/blogs">Blogs</Link></li> */}
          </ul>
          <div className="navbar-buttons ml-3 d-none">
            <button className="btn">Sign In</button>
            <button className="btn">Sign Up</button>
          </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </nav>



  

  
  );
}




export default Navbar;
