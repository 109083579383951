import React from 'react';
import { Link } from 'react-router-dom';
import './DeleteAccount.scss'; 

export default function DeleteAccount() {
  return (
    <div className="delete-account-container">
      <div className="delete-account-box">
        <p>Request for Data Deletion</p>
        <Link to="https://forms.gle/bK133oAohaau7qev9">
          <button className="delete-button">Click here</button>
        </Link>
      </div>
    </div>
  );
}
