import React from "react";
import clock from "../../assets/images/clock.png";
import Vector from "../../assets/images/Vector.png"
import Navbar from "../Navabar";
import Footer from "../Footer";
import "./BlogDetails.scss";
import { useNavigate } from "react-router-dom";


const SecondBlog = () => {
  const navigate = useNavigate();

  const scrollToContactUs = () => {
    console.log("pathname", window.location.href)
    if (window.location.href.endsWith("/blog-details-two")) {
      // window.location.href = "/";
      localStorage.setItem("isScroll", "true");
      navigate('/');
    }
    const contactUsSection = document.getElementById('contact-us');
    if (contactUsSection) {
      contactUsSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      <Navbar/>
    <div className="blogdetail-container p-lg-0 p-md-0 p-sm-0">
      <div className="row d-flex justify-content-center">
        <div className="col-lg-7 col-md-6 col-sm-10">
          <h2 className="blogtitle">Blogs</h2>
          <h3 className="blogdetail">Round-the-Clock EV Management: Powering Delhi's Electric Bus Revolution with Utility Expertise
</h3>
          <h5 className="dateandtime">23 May, 2024</h5>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-lg-7 col-md-6 col-sm-10">
          <div className="card border-0">
            <img src={clock} className="card-img-top" alt="Card" />
            <div className="card-body mt-4">
              <h2 className="blogheading">Introduction</h2>
              <p className="card-text">Delhi's ambitious electric bus target of 80% by 2025 presents a unique challenge and opportunity for its power utilities. Managing the massive influx of electric buses requires a strategic approach – round-the-clock EV management. This strategy, coupled with utility-managed public EV infrastructure, offers significant benefits for Delhi's grid stability and future-proofs its transportation system.
 </p><br></br>
              {/* <p className="card-text">So, In this blog, we will help you to understand What DERMS is and how DERMS fits into the vision of the Government Of India’s combat plan against climate change, driving positive change across the nation. </p> */}

 
              {/* <p className="card-text">Let’s be specific-  DERMS is a combination of hardware and software systems used in the field of energy management, particularly in the context of smart grids and renewable energy integration. DERMS enables the efficient management and optimization of various distributed energy resources, such as solar panels, wind turbines, energy storage systems, electric vehicles, and demand response programs. The system helps utilities and grid operators to monitor, control, and coordinate these distributed energy resources to ensure grid stability, reliability, and optimal energy use.</p> */}
              <h3 className="blogheading mt-lg-2 mt-md-2 mt-sm-2 mt-2">Why Round-the-Clock EV Management is Crucial:

</h3>
                <ul className="card-text mb-4">
                  <li className="dermslist"><strong>Smoothing Summer Peaks:</strong> Delhi's notorious summer heat coincides with peak electricity demand. Round-the-clock management incentivizes off-peak charging, typically at night. This mirrors the successful strategies employed by Shenzhen, China, a global leader in electric buses. Shenzhen utilizes time-of-use tariffs and smart charging technology to encourage off-peak bus charging. This "load shifting" reduces pressure on the grid during peak hours, potentially eliminating the need for expensive peak power plants – a strategy directly applicable to Delhi's situation.

</li>
                  <li className="dermslist"><strong>Winter Lifeline:</strong> Conversely, winter months see a dip in electricity demand. Here, EVs can act as a buffer. By strategically encouraging charging during these low-demand periods, EVs provide the necessary load to maintain grid stability and prevent voltage fluctuations. This strategy echoes the approach taken by cities like Oslo, Norway, another EV leader. Oslo utilizes smart charging technology to automatically adjust charging rates based on real-time grid conditions. During low-demand winter months, this system ensures enough EVs are drawing power to maintain grid stability.

.</li>

                  {/* <li className="dermslist"><strong>Demand Response Management:</strong> DERMS facilitates demand response programs by enabling utilities to remotely control and manage energy consumption from various sources, such as electric vehicles and smart appliances. This helps to alleviate peak demand periods, reduce stress on the grid, and avoid the need for expensive infrastructure upgrades.</li>
                  <li className="dermslist"><strong>Enhanced Grid Flexibility:</strong> DERMS provides utilities with greater flexibility and agility in managing the grid, allowing for faster response times to changes in energy supply and demand. This flexibility is particularly important in the context of the evolving energy landscape and the increasing adoption of distributed energy resources.</li> */}
                </ul>
                <h3 className="blogheading mt-lg-5 mt-md-2 mt-sm-2 mt-2">The Case for Utility-Managed Public EV Infrastructure:
</h3>
                <p className="card-text">While Delhi embarks on its EV journey, utility-managed public charging infrastructure offers several advantages
</p>
                <ul className="card-text">
                  <li className="dermslist"><strong>Grid Optimization Expertise:</strong> Unlike private operators, utilities have a deep understanding of the grid's capacity and real-time needs. This expertise allows them to optimize charging patterns for maximum grid stability as the number of EVs increases. Imagine a scenario where Delhi DISCOMs (power distribution companies) collaborate with bus depots. By analyzing real-time grid data, DISCOMs can create dynamic charging schedules that prioritize off-peak charging while ensuring enough buses are charged for peak operations.


</li>
                  <li className="dermslist"><strong>Standardization and Scalability:</strong>Utility-managed infrastructure ensures consistent charging experiences across the city, encouraging wider EV adoption. Standardized protocols streamline operations and facilitate future scalability as Delhi's EV fleet expands. Take the example of Los Angeles, a city actively deploying electric buses. Los Angeles Department of Water and Power (LADWP), the city's utility, manages public EV charging infrastructure. LADWP has implemented standardized protocols for charging equipment and data exchange, ensuring a seamless experience for EV users across the city. This paves the way for future expansion with minimal disruption.


.</li>
</ul>

<h3 className="blogheading">Learning from a Leader: Shenzhen's Battery Swapping Advantage

</h3>
              <p className="card-text">Beyond round-the-clock management, Delhi can glean valuable insights from Shenzhen's leadership in electric buses. One key advantage is Shenzhen's extensive network of battery swapping stations. This technology allows for rapid battery swaps, significantly reducing charging times and ensuring buses remain operational throughout their service. While implementing a full-fledged battery swapping network might require further analysis, Delhi could pilot such a system in key bus depots to assess its effectiveness in optimizing EV operations and grid stability.

 </p>
 <h3 className="blogheading mt-lg-5 mt-md-2 mt-sm-2 mt-2">The Road Ahead: Collaboration is Key:
</h3>
                <p className="card-text">Delhi's electric bus revolution hinges on collaboration between key stakeholders:

</p>
                <ul className="card-text">
                  <li className="dermslist"><strong>DISCOMs:</strong>Implementing round-the-clock EV management with dynamic charging schedules, investing in smart charging infrastructure, and collaborating with bus operators for optimized charging plans.



</li>
                  <li className="dermslist"><strong>Policymakers:</strong>Enacting policies that incentivize off-peak charging, exploring innovative financing models for further infrastructure development, and potentially offering subsidies for battery swapping technology pilot programs.



.</li>
<li className="dermslist"><strong>Public::</strong>Embracing electric vehicles and participating in demand-response programs to support grid stability.




.</li>
</ul>



 
                
                <p className="card-text">By embracing round-the-clock EV management and prioritizing utility-managed public charging infrastructure, Delhi can not only ensure a smooth transition to electric buses but also establish itself as a leader in sustainable urban transportation. This collaborative approach, coupled with learning from global leaders, will pave the way for a cleaner and more resilient future for Delhi's power grid and its citizens.


</p>

<p> Get in Touch  -  <span> <a href="https://calendly.com/trillectric/30min" target="_blank" rel="noopener noreferrer" className="meeting"> Schedule Meeting
  </a></span>  </p> 





              </div>
              <div className="card-footer box">
                <div className="card-footer-content">
                  <p className="card-text1">Unlock new corporate sustainability solutions for your business</p>
                  <button className="btn btn-primary">
    <a href="https://calendly.com/trillectric/30min" target="_blank" rel="noopener noreferrer">
        Get in touch <img className="arrowimg" src={Vector} />
    </a>
</button>                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SecondBlog;
