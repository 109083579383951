import React, { useEffect } from "react";
import "./OurBlogs.scss";
import Slider from "react-slick";
import BlogImageOne from "../assets/images/blog-bg.svg";
import daygraph from "../assets/images/daygraph.svg";
import battery from "../assets/images/battery.png";
import clock from '../assets/images/clock.png'
import island from "../assets/images/island.png";




import { Link, useLocation } from "react-router-dom";

function OurBlogs() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [pathname]);

  const settings = {
    dots: true, // Show dots for navigation
    infinite: true,
    speed: 500,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container">
      <div className="row">
        <div className="assessment-container">
          <div className="teach-container blogs-container px-lg-0 px-md-0 px-sm-0  pt-0">
            <div className="row">
              <div className="col-lg-8 col-md-6">
                <h2 className="title-section text-start">
                  Our<span className="free-Assessment ms-lg-3 ms-md-3 ms-sm-3 ms-2">Blogs</span>
                </h2>
                <p className="opinions">
                  Share and give your thoughts
                  <br className="d-lg-block d-md-block d-sm-block d-none" />
                  and opinions on the important subjects.
                </p>
                <Link to="/blogs">
                  <button className="custom_btn">Read More</button>
                </Link>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="blogs-card-container">
                  <Slider {...settings}>
                    <div className="bolg-card-space">
                      <Link to="/blog-details">
                        <div className="blog-card">
                          <div className="blog-bg-image">
                            <img src={BlogImageOne} alt="blog" />
                          </div>
                          <div className="post-description">
                            <div className="share-date">
                              <span>Dec 6, 2023</span>
                              <label className="ps-3">10 min read</label>
                            </div>
                            <h3>How DERMS is Revolutionizing India's Energy Landscape?</h3>
                          </div>
                        </div>
                      </Link>
                    </div>

                    <div className="bolg-card-space">
                      <Link to="/blog-details-two">
                        <div className="blog-card">
                          <div className="blog-bg-image">
                            <img src={daygraph} alt="blog" />
                          </div>
                          <div className="post-description">
                            <div className="share-date">
                              <span>18 April, 2024</span>
                              <label className="ps-3">8 min read</label>
                            </div>
                            <h3>Navigating the Duck Curve: Trillectric's Smart Solutions for Grid Stability</h3>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="bolg-card-space">
                      <Link to="/blog-details-three">
                        <div className="blog-card">
                          <div className="blog-bg-image">
                            <img src={battery} alt="blog" />
                          </div>
                          <div className="post-description">
                            <div className="share-date">
                              <span>19 May, 2024</span>
                              <label className="ps-3">8 min read</label>
                            </div>
                            <h3> Islanding Prevention and Microgrid Management Strategies  </h3>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="bolg-card-space">
                      <Link to="/blog-details-four">
                        <div className="blog-card">
                          <div className="blog-bg-image">
                            <img src={island} alt="blog" />
                          </div>
                          <div className="post-description">
                            <div className="share-date">
                              <span>21 May, 2024</span>
                              <label className="ps-3">8 min read</label>
                            </div>
                            <h3>Battery Storage Optimization: Strategies for Peak Shaving and Self-Consumption</h3>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="bolg-card-space">
                      <Link to="/blog-details-five">
                        <div className="blog-card">
                          <div className="blog-bg-image">
                            <img src={clock} alt="blog" />
                          </div>
                          <div className="post-description">
                            <div className="share-date">
                              <span>23 May, 2024</span>
                              <label className="ps-3">8 min read</label>
                            </div>
                            <h3>Round-the-Clock EV Management: Powering Delhi's Electric Bus Revolution with Utility Expertise
</h3>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurBlogs;
