import React from 'react';
import './Centralize.scss';


const Centralize = () => {
  return (
    <div className='my-home-component'>
    <div className="my-component">
      <div className='container'>
        <div className='centered-div'>
      <h1 className="heading text-lg-center text-md-center text-sm-start text-start">Centralize, Optimize,  <br></br> <span>Revolutionize</span> </h1>
      <p className="description text-lg-center text-md-center text-sm-start text-start">Empowering DISCOMS & DR Aggregators with Robust Hardware & <br className="d-lg-block d-md-block d-sm-block d-none"/>Data Infrastructure for DER Asset Management </p>
     
      </div>
      </div>
    </div>
    </div>
  );
};

export default Centralize;
